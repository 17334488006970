import React from 'react'
import Navbar from '../../components/navbar'
import BlogMain from '../../../static/images/insurance.jpg'


export default function Enterpriserisk() {
  return (
    <div>
       <Navbar/>
<section class=" pb-16 lg:pb-24 bg-white" style={{fontFamily:'poppins'}}>
  <div class="h-64 lg:h-144">
    <img class="w-full h-full object-cover" src={BlogMain} alt=""/>
  </div>
  <div class="relative container px-4 mx-auto -mt-24">
    <div class="max-w-5xl px-4 pt-12 lg:pt-20 mx-auto bg-gray-100">
      <div class="max-w-2xl mb-12 mx-auto text-center">
        {/* <div class="flex items-center justify-center mb-6 text-xs text-blue-500 font-semibold uppercase">
          <a href="#">Home</a>
          <span class="inline-block mx-2">
            <svg class="text-blue-500 h-3 w-3" viewbox="0 0 8 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.875 0L0 1.875L4.125 6L0 10.125L1.875 12L7.875 6L1.875 0Z"></path>
            </svg>
          </span>
          <a href="#">Blog</a>
          <span class="inline-block mx-2">
            <svg class="text-blue-500 h-3 w-3" viewbox="0 0 8 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.875 0L0 1.875L4.125 6L0 10.125L1.875 12L7.875 6L1.875 0Z"></path>
            </svg>
          </span>
          <a href="#">Article</a>
        </div> */}
        <h2 class="mb-6 text-3xl lg:text-4xl text-indigo-800 font-bold font-heading">Looking at Enterprise Risk Management from the Perspective of a Startup.</h2>
        <div class="flex items-center justify-center">
          {/* <div class="mr-6">
            <img class="w-14 h-14 object-cover" src="https://images.unsplash.com/photo-1607746882042-944635dfe10e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1050&amp;q=80" alt=""/>
          </div> */}
          <div class="text-left">
            {/* <h3 class="mb-2 text-lg text-gray-500 font-semibold font-heading">Sara Sampton</h3> */}
            <p class="text-blue-500 text-xs text-center">Published On November 30, 2021 | Raunak Jha</p>
          </div>
        </div>
      </div>
      <div class="max-w-3xl mx-auto -mb-6 ">
        <p class="mb-6  text-md lg:text-lg text-gray-600">Risks are an inevitable part of entrepreneurship and a structured approach to managing these risks is often ignored in the early stages of business. While none of these risks can be eliminated, it becomes essential that entrepreneurs learn how to manage uncertainties across departments to protect the objectives and enhance the growth of their business. By virtue of being nascent in the business world, startups face a host of risks that often threaten their own survival; scenarios such as lack of capital, insufficient liquidity, an inability to attract talent and customers and increasing competition are all too common. Therefore, it is imperative that startups implement robust processes and systems to identify and respond to vulnerabilities and threats across the value chain.</p> 
        <p class="mb-6  text-md lg:text-lg text-gray-600">Enterprise Risk Management is often seen as a domain of large corporate entities that requires huge investments but every crisis is a reminder that risk management is equally relevant to all businesses, irrespective of their size. Entrepreneurs, today, must remember that investors are no longer looking at simple sales forecasts and projections. They are looking at profitability, overall risk management strategy, and a startup’s potential to bounce back successfully from major threats or crises. Thus, prioritising risk in decision-making and operations can go a long way towards gaining investor’s confidence.
</p>
        <p class="mb-6  text-md lg:text-lg text-gray-600">As more and more businesses are transitioning to digital transformation, with the pandemic having imposed additional urgency, they are increasingly at risk for cyber vulnerabilities. Entrepreneurs and startups need to be mindful and ensure all processes are reviewed and realigned, if needed, to support remote working, while maintaining a secure ecosystem that protects valuable company and customer data, and is resistant to cyber- attacks. Startups have fewer defences to safeguard themselves when faced with uncertainty, and are among the first to be impacted. While assessing risk, the organisation must study the impact on their customers, or the sectors in which they conduct business, evaluate systematic risks, and implement mitigation measures. 

</p>
      
        <p class="mb-10  pb-10 text-md lg:text-lg text-gray-600">The tenets of enterprise risk management can help the startups build a risk culture, to anticipate and mitigate threats from all areas including human resources, procurement, supply chain marketing, sales and even reputation. RJAC, a risk management consulting firm, provides valuable assistance to startups, to manage their enterprise risks in a structured manner to minimise the impact of various uncertainties on their desired  business plans without compromising on their value offerings.

</p>






      </div>
    </div>
  </div>
</section>



    </div>
  )
}
